import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// router
import { AppRoutingModule } from './app-routing.module';
// components
import { AppComponent } from './app.component';
import { HomePageComponent } from 'app/components/home-page/home-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
