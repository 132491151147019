import { NgModule } from '@angular/core';
// router
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
// components
import { HomePageComponent } from 'app/components/home-page/home-page.component';
import { PageNotFoundComponent } from 'app/components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
